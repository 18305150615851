@use "@angular/material" as mat;
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@include mat.all-component-typographies();
@include mat.core();

$ib-blue: (
  50: #e5eefd,
  100: #bfd4f9,
  200: #94b8f5,
  300: #699cf1,
  400: #52a5ff,
  500: #2871eb,
  600: #2469e9,
  700: #1e5ee5,
  800: #1843be,
  850: #143291,
  900: #0f42dd,
  A100: #fff,
  A200: #d6dfff,
  A400: #a3b6ff,
  A700: #8aa2ff,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);

$ib-red: (
  50: #ffe3e5,
  100: #ffbabe,
  200: #ff8c93,
  300: #ff5e67,
  400: #ff3c47,
  500: #ff1926,
  600: #ff1622,
  700: #ff121c,
  800: #ff0e17,
  900: #ff080d,
  A100: #fff,
  A200: #fff4f4,
  A400: #ffc1c2,
  A700: #ffa7a8,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);

$ib-green: (
  50: #ebfbf4,
  100: #ccf6e4,
  200: #aaf0d2,
  300: #88eac0,
  400: #6fe6b3,
  500: #55e1a5,
  600: #4edd9d,
  700: #44d993,
  800: #3bd58a,
  900: #2acd79,
  A100: #fff,
  A200: #daffeb,
  A400: #a7ffcf,
  A700: #8dffc1,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #000,
    600: #000,
    700: #000,
    800: #000,
    900: #000,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);
$ib-primary: mat.m2-define-palette($ib-blue, 500);
$ib-accent: mat.m2-define-palette($ib-green, 500);

// The warn palette is optional (defaults to red).
$ib-warn: mat.m2-define-palette($ib-red, 500);

// Create the theme object (a Sass map containing all of the palettes).
$ib-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $ib-primary,
      accent: $ib-accent,
      warn: $ib-warn,
    ),
    typography: mat.m2-define-typography-config(),
    density: -1,
  )
);

@include mat.all-component-themes($ib-theme);
